import React from "react";
import '../../styles/semantic.min.css';
import {Breadcrumb, Container, Header, Icon, Image, Item} from "semantic-ui-react";
import LayoutContainer from "../../container/LayoutContainer";
import SEO from "../../components/seo/Seo";
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import {Link} from "@reach/router";
import PageHeader from "../../components/utils/PageHeader";
import DefaultSegment from "../../components/utils/DefaultSegment";
import programmImg from "../../components/what-we-do/assets/8pfp.png";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";
import layout from "../../templates/layout.module.css";
import MobileContainer from "../../container/MobileContainer";
import TabletContainer from "../../container/TabletContainer";

export default () => <LayoutContainer>
    <SEO title={'Förderprogramm'}/>
    <MobileTabletContainer>
        <MainNavigation/>
        <Container>
            <PageHeader title={'8 Punkte Förderprogramm'}/>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Section><Link to={'/'}><Icon name={'home'}/>Start</Link></Breadcrumb.Section>
                    <Breadcrumb.Divider/>
                    <Breadcrumb.Section><Link to={'/was-wir-tun/'}>Was wir tun</Link></Breadcrumb.Section>
                    <Breadcrumb.Divider/>
                    <Breadcrumb.Section active>Förderprogramm</Breadcrumb.Section>
                </Breadcrumb>
                <DefaultSegment>
                    <Container style={{marginBottom: '2rem', marginTop: '2rem'}}>
                        <Image src={programmImg} centered/>
                        <Header as={'h2'}>
                            Ziele & Aufgaben
                        </Header>
                        <p style={{fontWeight: 'bold'}}>Das Ziel der Deutschen Stiftung Kinderdermatologie ist es, die
                            Lebenssituation von Kindern und Jugendlichen mit Hauterkrankungen zu verbessern. Die
                            Förderungen dienen der direkten Unterstützung und einer bessere medizinische und
                            organisatorische Versorgung von hautkranken Kindern und Jugendlichen sowie deren Familien.
                            Das dafür konzipierte 8-Punkte-Förderprogramm bildet die Grundlage der Zuwendungen und
                            Vergabe von Fördermitteln.</p>
                        <blockquote>Ein strategisches Förderinstrument zur Verbesserung der Versorgung hautkranker
                            Kinder und Jugendliche
                        </blockquote>
                        <p>Mit der Deutschen Stiftung Kinderdermatologie steht ein einmaliges Förderinstrument zur
                            Verfügung, das eine bessere medizinische und organisatorische Versorgung von hautkranken
                            Kindern und Jugendlichen sowie deren Familien fördert und stärkt.
                            Die Stiftung fördert mit ihrem 8-Punkte-Förderprogramm ganz gezielt die Bereiche für eine
                            bessere Zukunft hautkranker Kinder und Jugendliche mit acht Schwerpunkten.
                            Die Förderungen sollen immer Anschubfinanzierung sein, damit die Projekte und Vorhaben zu
                            allgemeinverbindlichen und nachhaltigen Ergebnissen in der Versorgung von hautkranken
                            Kindern und Jugendlichen führen.</p>
                        <MobileContainer>
                            <Item.Group>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{margin: "0 auto"}}
                                          color='red' size='huge'
                                          name={'plus'}/>
                                    <Item.Content>
                                        <Item.Header>I.</Item.Header>
                                        <Item.Description>Direkte Unterstützung hilfsbedürftiger hautkranker Kinder und
                                            Jugendliche (DSK-Hilfsfonds)</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{margin: "0 auto"}}
                                          color='green' size='huge'
                                          name={'hubspot'}/>
                                    <Item.Content>
                                        <Item.Header>II.</Item.Header>
                                        <Item.Description>Bildung von Zentren für Kinder- und Jugenddermatologie und
                                            –allergologie (KIND)</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{margin: "0 auto"}}
                                          color='violet' size='huge'
                                          name={'hospital'}/>
                                    <Item.Content>
                                        <Item.Header>III.</Item.Header>
                                        <Item.Description>Unterstützung bestehender Einrichtungen zur Behandlung von
                                            hautkranken Kindern und Jugendlichen</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{margin: "0 auto"}}
                                          color='orange' size='huge'
                                          name={'pills'}/>
                                    <Item.Content>
                                        <Item.Header>IV.</Item.Header>
                                        <Item.Description>Förderung der Patientenschulung von Kindern und Jugendlichen
                                            mit verschiedenen Hauterkrankungen</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{margin: "0 auto"}}
                                          color='blue' size='huge'
                                          name={'university'}/>
                                    <Item.Content>
                                        <Item.Header>V.</Item.Header>
                                        <Item.Description>Förderung von Wissenschaft, Forschung und Lehre sowie
                                            Aufklärung der Öffentlichkeit im Bereich der
                                            Kinderdermatologie</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{margin: "0 auto"}}
                                          color='yellow' size='huge'
                                          name={'trophy'}/>
                                    <Item.Content>
                                        <Item.Header>VI.</Item.Header>
                                        <Item.Description>Jährliche Preisvergabe für hervorragende Arbeiten in
                                            Forschung, Praxis und Klinik mit dem DSK-Förderpreis
                                            Kinderdermatologie</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{margin: "0 auto"}}
                                          color='teal' size='huge'
                                          name={'graduation cap'}/>
                                    <Item.Content>
                                        <Item.Header>VII.</Item.Header>
                                        <Item.Description>Systematisches Stipendienprogramm „Fit für Kinderdermatologie“
                                            zur Förderung des begabten, Engagement bereiten und lernwilligen
                                            Nachwuchses</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{margin: "0 auto"}}
                                          color='purple' size='huge'
                                          name={'user md'}/>
                                    <Item.Content>
                                        <Item.Header>VIII.</Item.Header>
                                        <Item.Description>Aus- und Fortbildung von Ärzten und Pflegepersonals im Fach
                                            Kinderdermatologie (Grund-, Aufbau und Spezialseminar mit Zertifikat
                                            „Kinderdermatologie“)</Item.Description>
                                    </Item.Content>
                                </Item>

                            </Item.Group>
                        </MobileContainer>
                        <TabletContainer>
                            <Item.Group>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{marginRight: "1rem"}}
                                          color='red' size='huge'
                                          name={'plus'}/>
                                    <Item.Content>
                                        <Item.Header>I.</Item.Header>
                                        <Item.Description>Direkte Unterstützung hilfsbedürftiger hautkranker Kinder und
                                            Jugendliche (DSK-Hilfsfonds)</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{marginRight: "1rem"}}
                                          color='green' size='huge'
                                          name={'hubspot'}/>
                                    <Item.Content>
                                        <Item.Header>II.</Item.Header>
                                        <Item.Description>Bildung von Zentren für Kinder- und Jugenddermatologie und
                                            –allergologie (KIND)</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{marginRight: "1rem"}}
                                          color='violet' size='huge'
                                          name={'hospital'}/>
                                    <Item.Content>
                                        <Item.Header>III.</Item.Header>
                                        <Item.Description>Unterstützung bestehender Einrichtungen zur Behandlung von
                                            hautkranken Kindern und Jugendlichen</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{marginRight: "1rem"}}
                                          color='orange' size='huge'
                                          name={'pills'}/>
                                    <Item.Content>
                                        <Item.Header>IV.</Item.Header>
                                        <Item.Description>Förderung der Patientenschulung von Kindern und Jugendlichen
                                            mit verschiedenen Hauterkrankungen</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{marginRight: "1rem"}}
                                          color='blue' size='huge'
                                          name={'university'}/>
                                    <Item.Content>
                                        <Item.Header>V.</Item.Header>
                                        <Item.Description>Förderung von Wissenschaft, Forschung und Lehre sowie
                                            Aufklärung der Öffentlichkeit im Bereich der
                                            Kinderdermatologie</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{marginRight: "1rem"}}
                                          color='yellow' size='huge'
                                          name={'trophy'}/>
                                    <Item.Content>
                                        <Item.Header>VI.</Item.Header>
                                        <Item.Description>Jährliche Preisvergabe für hervorragende Arbeiten in
                                            Forschung, Praxis und Klinik mit dem DSK-Förderpreis
                                            Kinderdermatologie</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{marginRight: "1rem"}}
                                          color='teal' size='huge'
                                          name={'graduation cap'}/>
                                    <Item.Content>
                                        <Item.Header>VII.</Item.Header>
                                        <Item.Description>Systematisches Stipendienprogramm „Fit für Kinderdermatologie“
                                            zur Förderung des begabten, Engagement bereiten und lernwilligen
                                            Nachwuchses</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "2rem"}}>
                                    <Icon style={{marginRight: "1rem"}}
                                          color='purple' size='huge'
                                          name={'user md'}/>
                                    <Item.Content>
                                        <Item.Header>VIII.</Item.Header>
                                        <Item.Description>Aus- und Fortbildung von Ärzten und Pflegepersonals im Fach
                                            Kinderdermatologie (Grund-, Aufbau und Spezialseminar mit Zertifikat
                                            „Kinderdermatologie“)</Item.Description>
                                    </Item.Content>
                                </Item>

                            </Item.Group>
                        </TabletContainer>

                    </Container>
                </DefaultSegment>
            </Container>
        </Container>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer>
        <div className={layout.pageWrapper}>
            <MainNavigation/>
            <Container>
                <PageHeader title={'8 Punkte Förderprogramm'}/>
                <Container fluid>
                    <Breadcrumb>
                        <Breadcrumb.Section><Link to={'/'}><Icon name={'home'}/>Start</Link></Breadcrumb.Section>
                        <Breadcrumb.Divider/>
                        <Breadcrumb.Section><Link to={'/was-wir-tun/'}>Was wir tun</Link></Breadcrumb.Section>
                        <Breadcrumb.Divider/>
                        <Breadcrumb.Section active>Förderprogramm</Breadcrumb.Section>
                    </Breadcrumb>
                    <div style={{marginBottom: "2rem", marginTop: "1rem"}}>
                    <DefaultSegment>
                        <Container>
                            <Image src={programmImg} centered/>
                            <Header as={'h2'}>
                                Ziele & Aufgaben
                            </Header>
                            <p style={{fontWeight: 'bold'}}>Das Ziel der Deutschen Stiftung Kinderdermatologie ist es,
                                die Lebenssituation von Kindern und Jugendlichen mit Hauterkrankungen zu verbessern. Die
                                Förderungen dienen der direkten Unterstützung und einer bessere medizinische und
                                organisatorische Versorgung von hautkranken Kindern und Jugendlichen sowie deren
                                Familien. Das dafür konzipierte 8-Punkte-Förderprogramm bildet die Grundlage der
                                Zuwendungen und Vergabe von Fördermitteln.</p>
                            <blockquote>Ein strategisches Förderinstrument zur Verbesserung der Versorgung hautkranker
                                Kinder und Jugendliche
                            </blockquote>
                            <p>Mit der Deutschen Stiftung Kinderdermatologie steht ein einmaliges Förderinstrument zur
                                Verfügung, das eine bessere medizinische und organisatorische Versorgung von hautkranken
                                Kindern und Jugendlichen sowie deren Familien fördert und stärkt.
                                Die Stiftung fördert mit ihrem 8-Punkte-Förderprogramm ganz gezielt die Bereiche für
                                eine bessere Zukunft hautkranker Kinder und Jugendliche mit acht Schwerpunkten.
                                Die Förderungen sollen immer Anschubfinanzierung sein, damit die Projekte und Vorhaben
                                zu allgemeinverbindlichen und nachhaltigen Ergebnissen in der Versorgung von hautkranken
                                Kindern und Jugendlichen führen.</p>
                            <Item.Group>
                                <Item style={{marginBottom: "3rem"}}>
                                    <Icon style={{marginRight: "2rem"}}
                                          color='red' size='huge'
                                          name={'plus'}/>
                                    <Item.Content>
                                        <Item.Header>I.</Item.Header>
                                        <Item.Description>Direkte Unterstützung hilfsbedürftiger hautkranker Kinder und
                                            Jugendliche (DSK-Hilfsfonds)</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "3rem"}}>
                                    <Icon style={{marginRight: "2rem"}}
                                          color='green' size='huge'
                                          name={'hubspot'}/>
                                    <Item.Content>
                                        <Item.Header>II.</Item.Header>
                                        <Item.Description>Bildung von Zentren für Kinder- und Jugenddermatologie und
                                            –allergologie (KIND)</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "3rem"}}>
                                    <Icon style={{marginRight: "2rem"}}
                                        color='violet' size='huge'
                                          name={'hospital'}/>
                                    <Item.Content>
                                        <Item.Header>III.</Item.Header>
                                        <Item.Description>Unterstützung bestehender Einrichtungen zur Behandlung von
                                            hautkranken Kindern und Jugendlichen</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "3rem"}}>
                                    <Icon style={{marginRight: "2rem"}}
                                          color='orange' size='huge'
                                          name={'pills'}/>
                                    <Item.Content>
                                        <Item.Header>IV.</Item.Header>
                                        <Item.Description>Förderung der Patientenschulung von Kindern und Jugendlichen
                                            mit verschiedenen Hauterkrankungen</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "3rem"}}>
                                    <Icon style={{marginRight: "2rem"}}
                                          color='blue' size='huge'
                                          name={'university'}/>
                                    <Item.Content>
                                        <Item.Header>V.</Item.Header>
                                        <Item.Description>Förderung von Wissenschaft, Forschung und Lehre sowie
                                            Aufklärung der Öffentlichkeit im Bereich der
                                            Kinderdermatologie</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "3rem"}}>
                                    <Icon style={{marginRight: "2rem"}}
                                          color='yellow' size='huge'
                                          name={'trophy'}/>
                                    <Item.Content>
                                        <Item.Header>VI.</Item.Header>
                                        <Item.Description>Jährliche Preisvergabe für hervorragende Arbeiten in
                                            Forschung, Praxis und Klinik mit dem DSK-Förderpreis
                                            Kinderdermatologie</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "3rem"}}>
                                    <Icon style={{marginRight: "2rem"}}
                                          color='teal' size='huge'
                                          name={'graduation cap'}/>
                                    <Item.Content>
                                        <Item.Header>VII.</Item.Header>
                                        <Item.Description>Systematisches Stipendienprogramm „Fit für Kinderdermatologie“
                                            zur Förderung des begabten, Engagement bereiten und lernwilligen
                                            Nachwuchses</Item.Description>
                                    </Item.Content>
                                </Item>
                                <Item style={{marginBottom: "3rem"}}>
                                    <Icon style={{marginRight: "2rem"}}
                                          color='purple' size='huge'
                                          name={'user md'}/>
                                    <Item.Content>
                                        <Item.Header>VIII.</Item.Header>
                                        <Item.Description>Aus- und Fortbildung von Ärzten und Pflegepersonals im Fach
                                            Kinderdermatologie (Grund-, Aufbau und Spezialseminar mit Zertifikat
                                            „Kinderdermatologie“)</Item.Description>
                                    </Item.Content>
                                </Item>

                            </Item.Group>
                        </Container>
                    </DefaultSegment>
                    </div>
                </Container>
            </Container>
            <Footer/>
        </div>
    </DesktopContainer>


</LayoutContainer>